import * as React from "react"
import DocumentationContent from "../../components/DocumentationContent"
import DocumentationSidebar from "../../components/DocumentationSidebar"
import PropTypes from 'prop-types'
import { graphql, Link } from 'gatsby'
import Layout from '../../components/Layout'
import Header from '../../components/Header'
import Content, { HTMLContent } from '../../components/Content'
import Documentation from '../../components/Documentation'
import { CopyBlock, CodeBlock, atomOneDark, solarizedDark, xt256 } from "react-code-blocks";
import {Image, SlideshowLightbox} from "lightbox.js-react";
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { oneDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import Footer from "../../components/Footer"

export default function ImageComponent () {
  const images = [{title: "Photon", src: "https://source.unsplash.com/600x400/?pastel+cloud"}, 
  {title: "Inversion", src: "https://source.unsplash.com/500x400/?pastel+clouds"},
  {title: "Legion", src: "https://source.unsplash.com/600x400/?pastel+sky"},
  {title: "Inversion", src: "https://source.unsplash.com/600x400/?pastel"},
  {title: "Twenty Neue", src: "https://source.unsplash.com/600x400/?sky+pastel"},
  {title: "Geometrik", src: "https://source.unsplash.com/600x400/?dreamy+clouds"},         
  {title: "Achtung", src: "https://source.unsplash.com/600x400/?pastel+fruit"},];
  const showLineNumbers = true;
  const wrapLines = true;
  const codeBlock = false;

  return <Layout>
    <section className="section section--gradient font-inter bg_white">
      <Header />
    
        <div className="pt-4">
        <main className="flex-grow">

            <section>
            <div className="max-w-6xl mx-auto px-2 sm:px-2">
                <div className="pt-32 pb-12 md:pt-40 md:pb-20">

                {/* Main content */}
                <div className="md:flex md:justify-between" data-sticky-container>

                    <DocumentationSidebar />

                    <div className="md:flex-grow documentation font-inter">

                      {/* <Header /> */}
                      <div className="text-lg text-gray-600">
                        <h1 className="h1 text-gray-900 mb-4 font-bold">Image</h1>

                        <p className="mb-8">
                          This component displays a singular image lightbox with a fade-in and fade-out animation.
                          For displaying multiple images in a slideshow format, see the <Link className="text-blue-500"
                           to="/docs/SlideshowLightbox">Slideshow Lightbox</Link> 
                           component instead.
                        </p>
                        
                        <p className="list_desc">The following features are supported:</p>

                        <ul className="list-disc list ml-8">
                            <li><b>Zooming:</b> Users can zoom through the mouse-wheel or single-click on desktop, and pinch-to-zoom
                            on mobile devices.
                            </li>
                            <li> <b>Panning:</b> Once an image is zoomed into, the image can be panned by dragging the image through the mouse, or if on a mobile device, with a swipe-to-drag motion.</li>
                            <li> <b>Animations:</b>
                            When the user clicks on an image, a fade-in entry animation is displayed, which allows for a seamless transition effect between 
                            the content and the lightbox. A fade-out animation is displayed when the user exits from the lightbox.</li>
                      </ul>

                      <article className="mb-8 mt-4">
                        <h3 id="import" className="h3 text-gray-900 mb-4 text-3xl font-bold">Import</h3>
                        <SyntaxHighlighter language="jsx" style={oneDark} className="mb-8 codeBlock">
          {`import {Image} from "lightbox.js-react"`}
          </SyntaxHighlighter>

                      </article>

                    <h3 id="installation" className="text-gray-900 mb-4 text-3xl font-semibold" style={{ scrollMarginTop: '100px' }}>Usage</h3>
                    <p className="mb-8">
                      To include an image that displays a lightbox when clicked, simply add the following:
                    </p>
                    <SyntaxHighlighter language="jsx" style={oneDark} className="mb-8 codeBlock">
          {`<Image image={{src: "https://source.unsplash.com/Kk8mEQAoIpI/1600x1200", title: "Cyberpunk"}} />`}
          </SyntaxHighlighter>

                      <p className="mb-4 mt-8">
                      This will render an image as shown below. Try clicking it to see the animation in action:
                      </p>

                      <p className="flex mt-8 items-center text-base px-3 py-4 text-gray-800 bg-blue-100 border border-blue-200 rounded mb-8">
                        <svg className="w-4 h-4 fill-current text-blue-500 flex-shrink-0 mr-2" xmlns="http://www.w3.org/2000/svg">
                          <path d="M8 0a8 8 0 100 16A8 8 0 008 0zm2.629 11.618L8 10.236l-2.629 1.382.5-2.927-2.124-2.073 2.939-.427L8 3.528l1.314 2.663 2.939.427-2.126 2.073.502 2.927z" fill="#338CF5" fillRule="nonzero" />
                        </svg>
                        <span>Check out the <Link className="text-blue-600 hover:underline" href="/docs/SlideshowLightbox">SlideshowLightbox</Link> component to display multiple images in a slideshow format.</span>
                      </p>

                      <h3 id="fullexample" className="h3 text-gray-900 mb-8" style={{ scrollMarginTop: '100px' }}>Full example</h3>

                      <SyntaxHighlighter language="jsx" style={oneDark} className="mb-8 codeBlock">
          {`import React, { Component } from 'react'
import {Image} from 'lightbox.js-react'
import 'lightbox.js-react/dist/index.css'

class Demo extends Component {
    render() {
        return (
          <div>
            <Image image={{src: "https://source.unsplash.com/Kk8mEQAoIpI/1600x1200", title: "Cyberpunk"}} />
          </div>
        )
    }
}`}
          </SyntaxHighlighter>
                      <h4 className="h4 text-gray-900 mt-8 ">Full example using functional components</h4>
                      <p>Here's an example using a functional component instead:
                      </p>

                      <SyntaxHighlighter language="jsx" style={oneDark} className="mb-8 codeBlock">
          {`import React from 'react'
import {Image} from 'lightbox.js-react'
import 'lightbox.js-react/dist/index.css'
          
function Demo() {
return (
  <div>
  <Image image={{src: "https://source.unsplash.com/Kk8mEQAoIpI/1600x1200", title: "Cyberpunk"}} />
</div>
      )
}`}
          </SyntaxHighlighter>
          
                      <p>
                      This will display the following image, along with a lightbox that appears when the image is clicked:
                      </p>


                      <Image image={{src: "https://source.unsplash.com/Kk8mEQAoIpI/1600x1200", title: "Cyberpunk"}} className="w-3/5 mx-auto" />

                      <h3 className="mt-8">Available Themes</h3>

                      <p>There are 3 themes available, which can be selected via setting the `theme` prop:</p>

                      <ul className="list-disc list ml-8">
                          <li><b>day</b>: A light theme with a white background and gray icons</li>
                          <li><b>night</b>: A dark theme with gray icons</li>
                          <li><b>lightbox</b>: A theme with a semi-transparent gray background</li>
                      </ul>

                      <h4 className="text-gray-800">Day theme example</h4>
                      <Image image={images[0]} theme="day"/>

                      <h4 className="mt-8 text-gray-800">Night theme example</h4>
                      <Image image={images[0]} theme="night"/>

                      <h4 className="mt-8 text-gray-800">Lightbox theme example</h4>
                      <Image image={images[0]} theme="lightbox" />

                      <article className="mb-8 mt-8">
                        <h2 className="h2 text-gray-900 mb-4 text-3xl font-semibold">Customization</h2>

                        <h4>Theme</h4>
                      <p>
                        If you'd like to change the theme of the lightbox, this can be done by passing a theme name 
                        to the <code>theme</code> prop, with the options including: "lightbox", "night", "day"
                      </p>

                      <div>
                            <SyntaxHighlighter language="jsx" style={oneDark} className="codeBlock">
                            {`<Image theme="night" image={{src: "https://source.unsplash.com/Kk8mEQAoIpI/1600x1200", title: "Cyberpunk"}} />`}
                            </SyntaxHighlighter>
                        </div>

                      <h4>Background Color</h4>
                      <p>
                        If you'd like to customize the background color of the lightbox, this can be done by passing a color 
                        to the <code>color</code> prop, as a RGB, RGBA, HEX or CSS color name value:
                      </p>

                      <div>
                            <SyntaxHighlighter language="jsx" style={oneDark} className="codeBlock">
                            {`<Image backgroundColor="rgba(12, 12, 12, 0.8)" ... />`}
                            </SyntaxHighlighter>
                        </div>
                      
                      <h4>Icon Color</h4>
                      <p>
                        The icon colors can also be specified through the <code>iconColor</code> prop, and the color can be a RGB, 
                        RGBA, HEX or CSS color name value.
                      </p>

                        <div>
                            <SyntaxHighlighter language="jsx" style={oneDark} className="codeBlock">
                            {`<Image iconColor="rgba(12, 12, 12, 0.8)" ... />`}
                            </SyntaxHighlighter>
                        </div>

                        <h4>Full Screen</h4>
                      <p className="list_desc">
                        If you'd like the image to take up the screen's full available width and height, simply set the 
                        <code>fullScreen</code> prop to <code>true</code>. The default is <code>false</code>.
                      </p>

                      <div className="mb-8">
                            <SyntaxHighlighter language="jsx" style={oneDark} className="codeBlock">
                            {`<Image fullScreen={true} ... />`}

                            </SyntaxHighlighter>
                        </div>

                        <h4>Lightbox Image Styling</h4>
                      <p>
                      Custom classname(s) to be passed to the lightbox's image wrapper.
                      Just pass your classname(s) to the <code>lightboxImgClass</code> prop.
                      </p>

                        <div className="mb-8">
                            <SyntaxHighlighter language="jsx" style={oneDark} className="codeBlock">
                            {`<Image lightboxImgClass={"lightboxImg"} ... />`}
                            </SyntaxHighlighter>
                        </div>

                        <p>
                        You could then select the <code>img</code> component within the lightbox with CSS, such as:
                        </p>

                        <SyntaxHighlighter language="css" style={oneDark} className="codeBlock">
                            {`.lightboxImg img {
...
}`}
                            </SyntaxHighlighter>

                            <h4>Close Lightbox When Outside of Image Is Clicked</h4>
                      <p>
                        If you'd like to close the lightbox when the outside of the image is clicked, 
                        this can be done by setting the <code>modalClose</code> prop to <code>clickOutside</code>:
                      </p>

                      <div className="mb-8">
                            <SyntaxHighlighter language="jsx" style={oneDark} className="codeBlock">
                            {`<Image modalClose="clickOutside" ... />`}
                            </SyntaxHighlighter>
                        </div>

                        <h4>Show/Hide Certain Icons in the Controls</h4>
                      <p>
                        If you'd like to show/hide particular icons in the controls at the top of the lightbox, 
                        this can be done by toggling the respective values to true/false.
                      </p>

                      <p>The props are:</p>

                      <ul className="list list-disc ml-8">
                          <li>showFullScreenIcon</li>
                          <li>showMagnificationIcons</li> 
                        </ul>    


                      <div className="mb-8">
                            <SyntaxHighlighter language="jsx" style={oneDark} className="codeBlock">
                            {`<Image showMagnificationIcons={false} showSlideshowIcon={false} ... />`}
                            </SyntaxHighlighter>
                        </div>

                        <h4>Show Controls</h4>
                      <p>
                        If you'd like to remove the controls (in the top-right corner) of the lightbox, 
                        this can be done by setting the
                        <code>showControls</code> prop to <code>false</code>. The only icon that will be displayed is the close icon.
                      </p>

                      <div className="mb-8">
                            <SyntaxHighlighter language="jsx" style={oneDark} className="codeBlock">
                            {`<Image showControls={false} ... />`}
                            </SyntaxHighlighter>
                        </div>

                                

        <h4>Full Screen Images</h4>
                      <p className="list_desc">
                        If you'd like the images to take up the screen's full available width and height, simply set the 
                        <code>fullScreen</code> prop to <code>true</code>. The default is <code>false</code>.
                      </p>

                      <div className="mb-8">
                            <SyntaxHighlighter language="jsx" style={oneDark} className="codeBlock">
                            {`<SlideshowLightbox fullScreen={true}>
...
</SlideshowLightbox>`}
                            </SyntaxHighlighter>
                        </div>

                        <h4>Disable Image Zoom</h4>
                      <p className="list_desc">
                        Image zoom is provided by default, but if you'd like to disable the image zoom functionality, then set the
                        <code>disableImageZoom</code> prop to <code>true</code>.
                      </p>

                      <div className="mb-8">
                            <SyntaxHighlighter language="jsx" style={oneDark} className="codeBlock">
                            {`<SlideshowLightbox disableImageZoom={true}>
...
</SlideshowLightbox>`}
                            </SyntaxHighlighter>
                        </div>

        </article>

        <h3 id="quick" className="h3 text-gray-900 mb-8 font-semibold" style={{ scrollMarginTop: '100px' }}>Props</h3>

        <p className="mb-8">
          <b>theme</b>: String - The theme to be applied to the lightbox. Options include day, night, lightbox
        </p>

        <p className="mb-8">
          <b>backgroundColor</b>: String - the background color of the lightbox, as a CSS color name, RGBA value or HEX code
        </p>

        <p className="mb-8">
          <b>modalClose</b>: String - If set to the "clickOutside", the lightbox will close if the outside of the image is clicked.
        </p>

        <p className="mb-8">
          <b>iconColor</b>: String - the icon color for the lightbox, as a CSS color name, RGBA value or HEX code
        </p>

        <p className="mb-8">
          <b>fullScreen</b>: Boolean - Whether to display the image so that it takes up the screen's full width and height
        </p>

        <p className="mb-8">
          <b>showControls</b>: Boolean - Whether or not to display the controls. Only the close icon will be displayed if set to <code>false</code>.
          Specific controls can be removed, see below for more information.
        </p>

        <p className="mb-8">
          <b>showFullScreenIcon</b>: Boolean - Whether or not to display the full-screen icon
        </p>

        <p className="mb-8">
          <b>showMagnificationIcons</b>: Boolean - If set to <code>false</code>, the magnification icons are hidden
        </p>

        <p className="mb-8">
          <b>lightboxImgClass</b>: String - Custom classname(s) to be passed to the lightbox image's wrapper element
        </p>

        <p className="mb-8">
          <b>disableImageZoom</b>: Boolean - If you would like to disable image zoom functionality within the lightbox, 
          set the <code>disableImageZoom</code> prop to <code>true</code>
        </p>
         
      </div>

      {/* Related content */}
      <div className="mt-8">
        <h3 className="h3 mb-8 font-semibold">Related</h3>
        <Link className="flex justify-between items-center p-4 rounded border 
        border-gray-200 transition duration-300 ease-in-out bg-white shadow-md hover:shadow-lg mb-4" to="/docs/SlideshowLightbox">
          <div>
            <div className="text-normal font-medium mb-1">Slideshow Lightbox</div>
            <div className="text-sm text-gray-600">To display multiple images with thumbnails, be sure to check out the 
            SlideshowLightbox component.</div>
          </div>
          <svg className="w-4 h-4 fill-current text-blue-600 flex-shrink-0 ml-6" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.3 14.7l-1.4-1.4L12.2 9H0V7h12.2L7.9 2.7l1.4-1.4L16 8z" />
          </svg>
        </Link>
        <a className="flex justify-between items-center p-4 rounded border border-gray-200 transition 
        duration-300 ease-in-out bg-white shadow-md hover:shadow-lg mb-4" href="https://github.com/silvia-odwyer/lightbox.js">
          <div>
            <div className="text-normal font-medium mb-1">Feature Request</div>
            <div className="text-sm text-gray-600">Have an idea for a new feature? Be sure to let us know in the official 
            GitHub repository for Lightbox.js!</div>
          </div>
          <svg className="w-4 h-4 fill-current text-blue-600 flex-shrink-0 ml-6" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.3 14.7l-1.4-1.4L12.2 9H0V7h12.2L7.9 2.7l1.4-1.4L16 8z" />
          </svg>
        </a>
        <hr className="w-full h-px pt-px bg-gray-200 border-0 mt-8" />
      </div>

    </div>

                </div>

                </div>
            </div>
            </section>

            </main>
        </div>

    </section>
    <Footer overrideDarkTheme={true} />
    </Layout>
    
}